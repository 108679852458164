@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --default-line-height: 1.65;
    --color-primary-100: #525252;
    --color-primary-200: #3d3d3d;
    --color-primary-300: #292929;
    --color-primary-400: #141414;
    --color-primary-500: #000000; // rgb: 0, 0, 0
    --color-primary-600: #000000;
    --color-primary-700: #000000;
    --color-primary-800: #000000;
    --color-primary-900: #000000;
    --shadows-button-primary-focus: 0 0 0 1px rgba(28, 30, 36, 0.05), 0 2px 5px 0 rgba(28, 30, 36, 0.05), 0 1px 2.5px 0 rgba(28, 30, 36, 0.05),
        0 1px 2px 0 rgba(28, 30, 36, 0.05), rgba(0, 0, 0, 0.3) 0px 0px 0px 4px;
    --shadows-button-primary: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        rgba(0, 0, 0, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.08) 0px 2px 5px 0px;
}
