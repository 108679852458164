.Wrapper {
    @apply max-w-[330px] md:max-w-[360px] px-4;
    & + .Wrapper {
        @apply mt-0.5;
    }
    & + .Wrapper--is-typing {
        @apply mt-5;
    }
    &.Wrapper--assistant {
        @apply max-w-[360px] md:max-w-[390px] relative;
        & + .Wrapper--user {
            @apply mt-5;
        }
        .Message {
            @apply rounded-bl-none;
        }
    }
    &.Wrapper--user {
        @apply ml-auto;
        & + .Wrapper--assistant {
            @apply mt-5;
        }
        &.Wrapper--with-avatar {
            .Footer {
                @apply mr-9;
            }
        }
        .Author {
            @apply order-2;
        }
        .Message {
            @apply order-1 ml-auto bg-primary-500 text-white rounded-br-none;
        }
        .Footer {
            @apply ml-auto text-right;
        }
    }
    &.Wrapper--with-avatar {
        .Footer {
            @apply ml-9;
        }
    }
    &.Wrapper--is-typing {
        .Message {
            @apply bg-transparent px-0 h-7 flex items-center;
        }
    }
    &.Wrapper--button {
        .Message {
            @apply bg-white text-gray-900 border border-gray-300;
        }
    }
}
.Body {
    @apply flex items-end gap-2;
}
.Author {
    @apply w-7 shrink-0;
}
.AuthorImage {
    @apply w-7 h-7 rounded-full bg-gray-200 overflow-hidden rounded-br-none;
    img {
        @apply w-7 h-7 object-cover;
    }
}
.Message {
    @apply text-xs bg-gray-200 rounded-xl px-3.5 py-2.5 block text-left;
    p,
    pre,
    hr,
    ol,
    ul {
        @apply mb-4 last:mb-0;
    }
    p {
        code {
            @apply px-1 py-0 inline-flex whitespace-nowrap rounded bg-gray-100;
        }
    }
    ul {
        list-style: disc;
        @apply pl-4;
        ul {
            @apply mb-4;
            list-style: circle;
            ul {
                list-style: square;
                ul {
                    list-style: disc;
                    ul {
                        list-style: circle;
                        ul {
                            list-style: square;
                        }
                    }
                }
            }
        }
        ol {
            @apply mb-4;
        }
    }
    ol {
        list-style: decimal;
        @apply pl-4;
        ol {
            @apply mb-4;
            list-style: lower-latin;
            ol {
                list-style: lower-roman;
                ol {
                    list-style: decimal;
                    ol {
                        list-style: lower-latin;
                        ol {
                            list-style: lower-roman;
                        }
                    }
                }
            }
        }
        ul {
            @apply mb-3;
        }
    }
    ul,
    ol {
        li {
            @apply mb-4 last:mb-0;
        }
        ul,
        ol {
            li {
                @apply mb-1;
            }
        }
    }
    a {
        @apply underline hover:text-primary-500;
        svg {
            @apply inline mr-1 size-3 align-middle;
        }
    }
    hr {
        @apply border-t border-gray-400;
    }
    pre {
        @apply text-2xs rounded bg-gray-100;
    }
    code {
        letter-spacing: -0.3px;
        font-family: Consolas, Courier, "Apple SD \C0B0\B3CC\ACE0\B515   Neo", -apple-system, Lucida Grande, Apple SD Gothic Neo,
            "\B9D1\C740   \ACE0\B515", Malgun Gothic, Segoe UI, "\B3CB\C6C0", dotum, sans-serif;
        @apply text-2xs;
    }
}
.CodeWrapper {
    @apply contain-inline-size rounded relative bg-gray-100;
}
.CodeInner {
    @apply overflow-y-auto px-2.5 py-1.5;
}
.Image {
    @apply my-2 rounded-lg bg-white p-2 flex items-center gap-4;
}
.ImageInner {
    @apply w-20 shrink-0 rounded overflow-hidden;
}
.ImageBody {
    @apply w-full flex flex-col h-full justify-between gap-4;
}
.ImageName {
    @apply font-semibold line-clamp-2;
}
.Typing {
    @apply flex items-center gap-1;
}
.TypingSkeleton {
    //@apply bg-gray-200;
}
.Footer {
    @apply mt-1 text-3xs text-gray-600;
}
