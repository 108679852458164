.Wrapper {
    @apply text-gray-900 tracking-normal;
    &.Wrapper--inline {
        .Widget {
            @apply mx-auto;
        }
    }
    &.Wrapper--button {
        @apply fixed inset-0 z-[12000] pointer-events-none;
        .Content {
            @apply absolute inset-0 md:top-5 md:right-5 md:left-5;
            @media (min-width: 768px) {
                bottom: calc(theme("spacing.5") + theme("spacing.3") + 52px);
            }
        }
        .Widget {
            @apply flex items-end h-full;
        }
    }
    &.Wrapper--open,
    &.Wrapper--inline {
        .WidgetInner {
            @apply translate-y-0 opacity-100 pointer-events-auto;
        }
        .Button {
            @apply opacity-0 -translate-y-2 md:translate-y-0 md:opacity-100 pointer-events-none md:pointer-events-auto;
        }
        .ButtonIcon {
            svg {
                &:nth-child(1) {
                    @apply opacity-0 -translate-y-2 pointer-events-none;
                }
                &:nth-child(2) {
                    @apply opacity-100 translate-y-0;
                }
            }
        }
    }
    &.Wrapper--icon-right {
        &.Wrapper--button {
            .Widget {
                @apply ml-auto;
            }
        }
        .Button {
            @apply right-5 md:right-0;
        }
    }
    &.Wrapper--icon-left {
        .Button {
            @apply left-5 md:left-0;
        }
    }
    &.Wrapper--with-messages {
        .WidgetInner {
            @apply md:h-full;
        }
    }
}
.Content {
    @apply md:flex md:flex-row md:items-end;
}
.Widget {
    @apply w-full md:w-[430px] md:max-h-[700px] max-w-full;
}
.WidgetInner {
    @apply bg-white md:rounded-xl md:shadow-box-light overflow-hidden mt-auto h-full md:h-auto md:max-h-full w-full translate-y-3 opacity-0 transition-move ease-out-cubic duration-300;
}
.ScrollContainer {
    @apply relative flex flex-col h-full;
}
.Header {
    @apply bg-primary-500 text-white h-14 z-10 flex items-center justify-between px-4 shrink-0;
}
.HeaderCrisp {
    @apply flex items-center gap-2;
}
.HeaderCrispImage {
    @apply w-8 h-8 overflow-hidden bg-white rounded-full shrink-0;
    img {
        @apply w-8 h-8 object-cover;
    }
}
.HeaderCrispBody {
    @apply w-full;
}
.HeaderCrispTitle {
    @apply text-xs;
}
.HeaderCrispText {
    @apply text-3xs -mt-1 opacity-70;
}
.HeaderActions {
    @apply relative text-white flex items-center gap-0;
}
.Body {
    @apply grow overflow-y-auto;
}
.Messages {
    @apply py-6;
}
.Form {
    @apply p-3 shrink-0;
}
.FormInner {
    @apply flex items-end gap-x-3 rounded-xl bg-gray-100 pl-3.5 pr-2 py-1.5;
}
.Input {
    @apply w-full text-gray-900;
}
.Submit {
    @apply shrink-0;
}
.Button {
    @apply absolute bottom-5 z-10 transition-move ease-out-cubic duration-300 pointer-events-auto;
    @media (min-width: 768px) {
        bottom: calc((theme("spacing.3") + 52px) * -1);
    }
}
.ButtonIcon {
    @apply relative;
    svg {
        @apply transition-move duration-300 ease-out-cubic;
        &:nth-child(2) {
            @apply absolute top-0 left-0 opacity-0 translate-y-2;
        }
    }
}
.Branding {
    @apply pt-3 flex items-center justify-center gap-6;
}
.BrandingCell {
    @apply flex items-center gap-1;
}
.BrandingCellItem {
    font-size: 11px;
    @apply text-gray-700;
    img,
    svg {
        @apply size-3;
    }
    a {
        @apply border-b border-dotted border-gray-400;
    }
}
